import * as React from 'react';

export default function ({ status, amount, isSignatureRequired }) {

    function formatCurrency(value) {
        const formattedAmount = new Intl.NumberFormat('en-AU',
            { style: 'currency', currency: 'AUD' }).format(value);
        return formattedAmount;
    }

    return (
        <>
            <div className="flex flex-col items-center">
                <h2 className="text-6xl text-center font-semibold text-green-600 ">
                    {status}
                </h2>
                {status === 'REVERSED' && isSignatureRequired &&
                    <div className="text-4xl text-center font-semibold text-red-600 mt-4 ">Please try a different card or seek help from the staff.The current card requires a signature for verification.</div>
                }
                <h2 className="text-center text-5xl font-semibold text-gray-800 mt-8">
                    Transaction Amount
                </h2>
                <p className="mt-5 text-4xl text-green-600 font-bold">
                    {formatCurrency(amount)}
                </p>
            </div>
        </>
    );

}